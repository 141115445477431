<template>
  <div v-html="content" class="content"></div>
</template>

<script>
const Mustache = require("mustache");

const Documents = {
  cookie: {
    no:
      "https://docs.google.com/document/d/1KC5Q2qXWPjG6jcvp3NvG37CnVFE6rci6lVScI0ZHaOM/pub",
    dk:
      "https://docs.google.com/document/d/1H6SZQeBBrrTP7uqDhs8dIla2-Vh5vEP45u6nu79wmT4/pub",
    uk:
      "https://docs.google.com/document/d/1OWrPTtDYDr4X9OCAtgHzFEtf7fiupoKnqeVfkWyH9KE/pub",
    se:
      "https://docs.google.com/document/d/1DMIS78iRsYleCojo8L3htx2vTYxJOl887a-kxnNNgZE/pub",
  },
  "processor-agreement": {
    no:
      "https://docs.google.com/document/d/e/2PACX-1vQnq1Vfg3Br4vlYOBin03M7X39MNYFpXZA_W0NqT8-oU89KX3EUZdFOt5P93ShjuOmq-3kC9dBBoREM/pub",
    dk:
      "https://docs.google.com/document/d/1xydPZimwjaoisynSiCVfPFrwBZFgmCQVKaK8PufM0NU/pub",
    uk: "",
    se:
      "https://docs.google.com/document/d/1cq5Q4n2Ya0aJhmVuTXCVc4mXU_qDph27O2rHQ4ZWCzQ/pub",
  },
  privacy: {
    no:
      "https://docs.google.com/document/d/e/2PACX-1vQt4tHOei7cVvIEY7-WLChtOUpBPWMh5y_c450cNI-zA4r2qLTYaJKTSfrbDOdWokzahxYnOKhp3bKk/pub",
    dk:
      "https://docs.google.com/document/d/1WqvJEV1klb3b168yOHlOds8UoAR383pK_fR9Y2LFX80/pub",
    uk:
      "https://docs.google.com/document/d/1o3-nM-r7hMd67cNS-2yxY-Sl_yXl0D_yGEKSCFMrP90/pub",
    se:
      "https://docs.google.com/document/d/1lbGj3TQmdt7tVg5iQ8Q4LhDq_H2rl4MoAhXRHQRYa68/pub",
  },
  sla: {
    no:
      "https://docs.google.com/document/d/e/2PACX-1vTgltjppM4YvPGB_xn_ueYa27F3lNzO40nov_f_TeY-k4vq8BJV6OyvpGc9Eu4fEIIPlyZN2OFgjIXn/pub",
    dk:
      "https://docs.google.com/document/d/17ao_UnKpYp9WjXQc40qikKcdhLfajFa69S0JlXmal0g/pub",
    uk:
      "https://docs.google.com/document/d/1hNRBNI89eX8YZ6SA8L90Fkui3obOJbYTzRs700r8isY/pub",
    se:
      "https://docs.google.com/document/d/1sb7sRZRBVKIryCjw8RkTwaJERH6fWy5XrY5uplUd8sI/pub",
  },
  "privacy-tenant": {
    no:
      "https://docs.google.com/document/d/e/2PACX-1vTymE3tvH_2kyd9EQGm6-9V2Ao_AdmSfgJFmsrL6H1uJRu_d9EUVHSSnOdG4ELlG3GK2jQ3naVYbYE8/pub",
    dk:
      "https://docs.google.com/document/d/1YfH1qpis8c9Vv8Kx7mTHCQkyWE0zV_dBSK_1keRq2XE/pub",
    uk:
      "https://docs.google.com/document/d/1V7SzQlfVtPZxI8chAfPrMJuxzEAhxvHxHK3kSxQBS6U/pub",
    se:
      "https://docs.google.com/document/d/1M6ArjZBO3v8rHX7TMsaS_wtFugHj5KA7-MZQCItxddg/pub",
  },
};

export default {
  props: {
    doc: String,
    region: String,
  },
  data() {
    return {
      content: null,
    };
  },
  computed: {
    host() {
      if (parent !== window) return new URL(document.referrer).host;
      return window.location.host;
    },
    url() {
      const doc = Documents[this.doc];
      if (!doc) return;

      return doc[this.region];
    },
  },
  mounted() {
    if (!this.url) return;

    var xhr = new XMLHttpRequest();
    xhr.open("GET", this.url + "?embedded=true", true);
    xhr.onload = () => {
      // display response
      this.content = Mustache.render(xhr.responseText, {
        domain: this.host,
        ...this.$route.query,
      });
    };
    xhr.send();
  },
};
</script>

<style lang="scss">
.content {
  padding: 2rem;
  margin: 0 auto;
  max-width: 750px;

  h2 {
    margin: 0;
  }

  p,
  li {
    line-height: 1.5;
  }

  ul {
    margin-top: 1rem;
  }

  table {
    margin: 1rem 0;
    width: 100%;
  }

  li {
    margin-left: 0.5rem;
    padding-bottom: 0;
    padding-top: 0 !important;

    & + li {
      padding-top: 0.5rem;
      margin-top: 0.5rem;
    }
  }

  .doc-content {
    padding: 0;
  }
}
</style>
