import Vue from "vue";
import VueRouter from "vue-router";
import Legal from "../views/Legal.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/:doc/:region",
    name: "Legal",
    props: true,
    component: Legal,
  },
  {
    path: "/:doc",
    name: "LegalRedirect",
    redirect: "/:doc/no",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
